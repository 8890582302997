
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Card, CardGroup } from 'react-bootstrap';
import './DungeonGenerationInfoPanel.css';
import ZavrImage from './../../images/zavr.webp';
import SSTImage from './../../images/SST.webp';
/*const options = [
  {
    name: 'Enable backdrop (default)',
    scroll: false,
    backdrop: true,
  },
  {
    name: 'Disable backdrop',
    scroll: false,
    backdrop: false,
  },
  {
    name: 'Enable body scrolling',
    scroll: true,
    backdrop: false,
  },
  {
    name: 'Enable both scrolling & backdrop',
    scroll: true,
    backdrop: true,
  },
];*/

function LockwoodPublishingInfoPanel({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  //https://react-bootstrap.github.io/components/cards/
  return (
    <>
      <button className='astext' onClick={toggleShow}><u>Read More</u></button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><h1>XR Games</h1></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ContentBlock>
          </ContentBlock>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default LockwoodPublishingInfoPanel;

function ContentBlock() {
  return (
    <>
      <Card>
        <Card.Header ><h5>Overview </h5>
          <a href="https://www.xrgames.io/" target="_blank">Visit XR Games</a>
          <br></br>
          <a href="https://zombiearmy.com/" target="_blank">ZombieArmyVR</a>
        </Card.Header>

        <Card.Body>
          <Card>
            <Card.Body>
              <Card.Text>
                <h2>Key Tech</h2>
                <ul>
                  <li>Unity</li>
                  <li>C#</li>
                  <li>PSVR2 & PS5</li>
                  <li>Azure Devops (source/build pipelines)</li>
                  <li>Linux/Windows Servers</li>
                  <li>Oculus/Meta</li>
                  <li>Steamworks</li>
                  <li>Open XR</li>
                  <li>Git/Git LFS</li>
                  <li>bHaptics & ForceTube</li>
                </ul>

                <h2>Projects</h2>

                <h3>Zombie Army VR - Lead Programmer</h3>
                <img className='image' src={ZavrImage} alt={"ZombieArmyVR"} />
                <h4>Developed by XR Games | Published by Rebellion</h4>

                <p>The project of Zombie Army VR was to make a VR version of their flat screen games to continue within the Zombie Army Universe.
                  This game was to be a single player and multiplayer co-op experience with a minimum of 8 hours of campaign.
                  As part of development we were simshipping on Meta Quest 2/pro/3, PSVR2, PCVR (Rift/Steam) along with various additional features to enhance the standard hardware.
                  Some of the notable additional features we included were: Fixed Foveated Rendering(FFR) and FidelityFX Super Resolution(FSR),
                  Eye Tracking, Adaptive Triggers, bHaptics haptic peripherals, ForceTube peripherals, and various other platform specific features.
                  Finally all of this had to run at the device standard refresh rate: Quest - 72, PSVR2 - 90, PCVR - headset dependent.
                </p>

                <p>
                  I joined as Lead programmer on this project after pre-production where I managed a core team of 5 and up to 10 at various points when the team size increased. I directed and supported all staff, along with planning and support both within the department and externally during the course of the project.
                  This ranged from interns all the way up to experienced seniors and contractors. During this project I instigated proper capacity tracking and task planning that started to be adopted by the wider company.
                  Personally, I helped develop more of the background processes like difficulty, loading pipelines, Asset Management, and external hardware integration. I also took a large role with Zombies near the end of the project along with performance profiling.
                  A lot of the tools required for this project were developed in parallel to the main project requiring close monitoring and assistance to meet the requirements with the relatively tight deadlines.
                </p>

                <p>Some notable challenges with Quest we overcame on this project were memory/storage factors. Meta only allows up to 2GB APK files and additional OBB files to supplement gameplay content.
                  Unity only allows the exporting of a singular OBB. We managed to utilise Addressables and custom build scripts to allow our (larger than 6GB) game to be uploaded and played.
                  This also posed an issue with the fact Quest 2 only really gave us 3.5gb of active game memory to use, and given that this also includes render memory it posed quite a challenge.
                </p>
                <p>
                  PSVR2 posed a challenge with it being very new hardware and some features not being supported by the more 'stable' versions of Unity. One such issue was URP not supporting FSR when using eye tracking and Single pass Instanced rendering.
                  We managed to find a middle ground given that moving to multipass rendering is not a feasible solution in a game with the lighting and processing requirements we had.
                </p>
                <p>
                  We also had to manage the fact that on the lower hardware (this included min spec PC) we had to account for the extra processing and overhead required to sync all network objects within the game.
                  The project and subsequently networking code evolved over the time we developed where in some instances we were shaving 10's of milliseconds from points.
                  Developing these areas and the profiling that came from it did wonders to highlight where things may appear fine in controlled testing but may not identify issues when implemented at a game wide scale.
                </p>


                <h3>Starship Troopers - Lead Programmer (Additional support)</h3>
                <img className='image' src={SSTImage} alt={"StarshipTroopersContinuum"} />
                <h4>Developed by XR Games | Published by Sony Pictures Virtual Reality</h4>
                <p>
                  I was only supporting on Starship Troopers (SST) due to developing various parts during ZAVR that could be directly integrated into SST. I was also availible for assistance when required by any member of the team.
                </p>
                <p>
                  My main contributions were the split binary extended OBB files for android and their addressable setup; advising and helping integrate a smooth loading pipeline; various performance improvements due to profiling and debugging.
                  On a smaller scale, I offered help with UI icons, telegraphing, and gameplay improvements. Some directly with staff and others by partaking in play tests.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </>
  );
}